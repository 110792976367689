.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.unlimited {
  color: rebeccapurple;
}

.btn-outline-purple {
  color: rebeccapurple;
  border: solid 1px rebeccapurple;
}

.btn-outline-purple:hover {
  background-color: black;
  border: solid 1px rebeccapurple;
  color: white;
}

.btn-purple {
  background-color: rebeccapurple;
  border: solid 1px rebeccapurple;
}

.btn-primary:hover {
  background-color: black;
  border: solid 1px rebeccapurple;
}