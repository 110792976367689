
.definitions > th {
    border: 1px solid black;
    padding: 5px;
}

.definitions > tbody > tr > td {
    border: 1px solid black;
    padding: 5px;
}

.withBorders > tbody > tr > td {
    border: 1px solid black;
    padding: 5px;
}

.boiler-field {
    color: black;
    /* font-weight: bold */
}