

.footer-container {
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .footer-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
}