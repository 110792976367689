.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.rounded-img {
  border: solid 1px lightgray;
  border-radius: 10px;
}

@media (max-width: 576px) {
  .rounded-img {
    border-radius: 5px;
  }
}

.instant {
  color: rebeccapurple;
}

.purple-button {
  background-color: rebeccapurple;
  border-color: rebeccapurple;
}

.back {
  background: url(swoosh.svg) no-repeat;
  background-size: 100% 100%;
}
