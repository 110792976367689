

.thumbnail {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 1) 0px 2px 4px, rgba(0, 0, 0, 1) 0px 7px 13px -3px, rgba(0, 0, 0, 1) 0px -3px 0px inset;
}


.bg-purple {
    background-color: #3a366d;
}

.text-light-grey {
    color: lightgray;
}