/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}


/*
.btn-primary:hover {
  background-color: black;
  border: solid 1px rebeccapurple;
} */